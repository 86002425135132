import React from "react";
import Button from "react-bootstrap/Button";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function GenericCard({
  title,
  text,
  image,
  imageFixedPath,
  link,
  price,
  buttonBackgroundColor,
  action,
  to,
  product,
  handleButtonClick,
  km,
  anio,
  transmision,
  from,
  whatsappButton,
  compatible,
}) {
  const imgToRender = () => {
    return (
      <Card.Img
        variant="top"
        style={{
          maxWidth: "100%",
          maxHeight: "14rem",
          minHeight: "14rem",
          objectFit: "cover",
        }}
        src={imageFixedPath + image}
      />
    );
  };

  //console.log(product, from, "desde generic card");

  return (
    <Card
      className="shadow my-2"
      style={{ width: "100%", height: "auto", maxHeight: "34rem" }}
    >
      {/*  <Card.Title className="text-center mt-2 font-weight-bold">
        <h3>{text}</h3>
      </Card.Title> */}
      {from ? (
        <Link
          to={{
            pathname: link,
            state: {
              product: product,
            },
          }}
          onClick={() => handleButtonClick(product)}
        >
          {imgToRender()}
        </Link>
      ) : (
        imgToRender()
      )}
      <Card.Body style={{ backgroundColor: "#f5f5f5" }}>
        <Card.Title
          style={{ minHeight: "3rem", borderBottom: "1px solid lightgray" }}
        >
          {title}
        </Card.Title>
        <Card.Text className="m-0 p-0">{text}</Card.Text>
        {anio || km || transmision ? (
          <Card.Text className="m-0 p-0" style={{ fontSize: "12px" }}>
            {anio} | {transmision} | <b>km</b> {km}
            <br></br>
          </Card.Text>
        ) : (
          <Card.Text className="m-0 p-0" style={{ fontSize: "12px" }}>
            {compatible}
          </Card.Text>
        )}
        {(from === "accesorios" || from === "repuestos") && (
          <Card.Text className="mt-1 mb-0">
            <b style={{ fontSize: "12px" }}>
              Precio público sin impuestos nacionales:{" "}
              {Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(Number(price.replace(/\./g, "")) / 1.21)}
            </b>
          </Card.Text>
        )}
        <Card.Text className="mt-1">
          {product && product.moneda === "u$d"
            ? "USD "
            : from !== "planes" && "$ "}
          {price}
        </Card.Text>

        <Row className="m-0 p-0">
          <Col className="m-0 p-0">
            <Link
              to={{
                pathname: to,
                state: {
                  product: product,
                  link: link,
                },
              }}
            >
              <Button
                size="sm"
                onClick={() => handleButtonClick(product)}
                variant="outline-light"
                style={{
                  backgroundColor: buttonBackgroundColor,
                  borderColor: "transparent",
                  fontWeight: "bold",
                }}
              >
                {action}
              </Button>
            </Link>
            {whatsappButton && whatsappButton}
          </Col>
          {from !== "planes" && (
            <Col className="text-right">
              <a
                href={`https://wa.me?text=El link para compartir este producto es: https://store.autoprana.com.ar/v2/${encodeURIComponent(
                  "#"
                )}/${from}${
                  from === "usados" ? "/catalogo?idInterno=" : "?id="
                }${from === "usados" ? product.id_interno : product.id}`}
                data-action="share/whatsapp/share"
              >
                <FontAwesomeIcon
                  icon="share"
                  style={{ color: "black" }}
                  className="mt-2"
                />
              </a>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}
